import "./Client.scss";
import linktown from "../../media/client/linktown.png";
import baswara from "../../media/client/baswara.png";
import enilub from "../../media/client/eni.png";
import mimosa from "../../media/client/mimosa.png";
import johar from "../../media/client/johar.png";
import mabowl from "../../media/client/mabowl.png";
import proflight from "../../media/client/proflight.png";
import broad from "../../media/client/board-indonesia.png";
import lovanastory from "../../media/client/lovanastory.png";
import intipesona from "../../media/client/intipesona.png";
import matalokal from "../../media/client/matalokal.png";
import inticosmetic from "../../media/client/inticosmetic.png";
import paiumrah from "../../media/client/paiumrah.png";
import paipai from "../../media/client/paipai.png";
import mansurabaya from "../../media/client/man-surabaya.png";
import ruangjenuh from "../../media/client/ruang-jenuh.png";
import maipark from "../../media/client/maipark.png";

const Client = () => {
  const clientList = [
    { src: linktown, alt: "Linktown" },
    { src: baswara, alt: "PT Baswara Kreasi Digital" },
    { src: enilub, alt: "Eni Lubricant" },
    { src: mimosa, alt: "Mimosa Grup" },
    { src: johar, alt: "The Johar Mansion" },
    { src: mabowl, alt: "Ma bowl" },
    { src: proflight, alt: "Proflight" },
    { src: broad, alt: "Broad Indonesia" },
    { src: lovanastory, alt: "Lovanastory" },
    { src: intipesona, alt: "PT Inti Pesona Lestari" },
    { src: matalokal, alt: "Matalokal" },
    { src: inticosmetic, alt: "Inticosmetic" },
    { src: paiumrah, alt: "PaiUmrah Travel" },
    { src: paipai, alt: "Paipai Travel" },
    { src: mansurabaya, alt: "Man Kota Surabaya" },
    { src: ruangjenuh, alt: "Ruang Jenuh Coffee" },
    { src: maipark, alt: "Maipark" },
  ];
  return (
    <section className="section-client" id="client">
      <div className="container-client">
        <div className="title">
          Layanan kami dipercaya oleh yang terbaik di bidangnya
        </div>
        <div className="client-list">
          {clientList.map((client, index) => (
            <div className="client-box" key={index}>
              <img src={client.src} alt={client.alt} loading="lazy" />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Client;
