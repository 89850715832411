import React from "react";
import Hero2 from "../../section/Hero/HeroV2";
import "./Home.scss";
import About2 from "../../section/About/AboutV2";
import Client from "../../section/Client/Client";
import Webdev2 from "../../section/Webdev/WebdevV2";
import Sosmed2 from "../../section/Sosmed/SosmedV2";
import Appdev2 from "../../section/Appdev/AppdevV2";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Testimoni2 from "../../section/testimoni/TestimoniV2";
const Home = () => {
  return (
    <div className="app">
      <Hero2 />
      <About2 />
      <Client />
      <Webdev2 />
      <Sosmed2 />
      <Appdev2 />
      <Testimoni2 />
    </div>
  );
};

export default Home;
