import React, { useEffect, useRef, useState } from "react";
import "./WebdevV2.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import logopaipai from "../../media/client/paipai.png";
import logopaiumroh from "../../media/client/paiumrah.png";
import logointicosme from "../../media/client/inticosmetic.png";
import logolovanastory from "../../media/client/lovanastory.png";
import lovana from "../../media/lovanastory.webp";
import nticosme from "../../media/inticosmetic.webp";
import paiumroh from "../../media/paiumroh2.webp";
import paipai from "../../media/paipai2.webp";
import linktown from "../../media/mockup-linktown2.webp";
import logolinktown from "../../media/client/linktown.png";
import { ReactComponent as ArrowSlider } from "../../media/arrow-right.svg";
import { ReactComponent as Arrowbtn } from "../../media/up-right.svg";
import { ReactComponent as Line1 } from "../../media/bg-ribbon1.svg";
import { ReactComponent as Line2 } from "../../media/bg-ribbon2.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProgressProvider from "./ProgressProvider";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
const Webdev = () => {
  const itempoints = [
    "UI/UX Design",
    "SEO Dinamis",
    "Total Storage 20GB",
    "CMS CRUD Data",
    "Report Traffic",
    "Google Optimization",
    "Full Licensed",
    "Dedicated Hosting",
    "Admin",
    "Blog / Article",
  ];
  const slidesData = [
    {
      img: `${lovana}`,
      title: "Lovanastory",
      performance: "84",
      accessibility: "80",
      bestPractice: "100",
      seo: "100",
      logo: `${logolovanastory}`,
      tags: ["Wordpress"],
      link: "#",
    },
    {
      img: `${linktown}`,
      title: "Linktown",
      performance: "84",
      accessibility: "87",
      bestPractice: "100",
      seo: "100",
      logo: `${logolinktown}`,
      tags: ["Laravel", "Wordpress"],
      link: "#",
    },
    {
      img: `${nticosme}`,
      title: "PT. Inticosmetic Lestari",
      performance: "84",
      accessibility: "80",
      bestPractice: "100",
      seo: "100",
      logo: `${logointicosme}`,
      tags: ["Wordpress"],
      link: "#",
    },
    {
      img: `${paiumroh}`,
      title: "Pai Umroh",
      performance: "84",
      accessibility: "80",
      bestPractice: "100",
      seo: "100",
      logo: `${logopaiumroh}`,
      tags: ["Wordpress"],
      link: "#",
    },
    {
      img: `${paipai}`,
      title: "Paipai Travel",
      performance: "84",
      accessibility: "80",
      bestPractice: "100",
      seo: "100",
      logo: `${logopaipai}`,
      tags: ["Wordpress"],
      link: "#",
    },
  ];
  const sliderRefs = useRef([]);

  // Function to add refs
  const addToRefs = (el) => {
    if (el && !sliderRefs.current.includes(el)) {
      sliderRefs.current.push(el);
    }
  };

  // Function to navigate to the next slide in all sliders
  const next = () => {
    sliderRefs.current.forEach((slider) => {
      if (slider) slider.slickNext();
    });
  };

  // Function to navigate to the previous slide in all sliders
  const previous = () => {
    sliderRefs.current.forEach((slider) => {
      if (slider) slider.slickPrev();
    });
  };
  const [settings, setSliderSettings] = useState({
    swipe: false,
    dots: false,
    infinite: true,
    arrows: false,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false, // No fade by default
    responsive: [
      {
        breakpoint: 1239,
        settings: {
          swipe: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: true,
          lazyLoad: false,
          infinite: true,
          dots: false,
          fade: false,
        },
      },
      {
        breakpoint: 599,
        settings: {
          swipe: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
          lazyLoad: false,
          infinite: true,
          dots: false,
          fade: false,
        },
      },
    ],
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1239) {
        setSliderSettings((prevSettings) => ({
          ...prevSettings,
          fade: true, // Enable fade for 1024px and below
        }));
      } else {
        setSliderSettings((prevSettings) => ({
          ...prevSettings,
          fade: false, // Disable fade for larger screens
        }));
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initialize on component mount

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="webdevcont" id="webdevelopment">
      <div className="head-webdev">
        <div className="headleft">
          <div className="label">
            Yuk cek karya seru dari layanan Web Development kami yang penuh
            kreativitas dan inovasi ini.
          </div>
        </div>
        <div className="headright">
          <h2 className="title">WEB DEVELOPMENT</h2>
        </div>
      </div>
      <div className="showcase-content-webdev">
        <div className="slider">
          <div className="slider-item1">
            <Slider ref={addToRefs} {...settings} initialSlide={0}>
              {slidesData.map((slide, index) => (
                <img
                  src={slide.img}
                  key={index}
                  alt={slide.title}
                  loading="lazy"
                />
              ))}
            </Slider>
          </div>
          <div className="slider-item2">
            <Slider ref={addToRefs} {...settings} initialSlide={1}>
              {slidesData.map((slide, index) => (
                <div className="slider-container" key={index}>
                  <div className="brand">
                    <img src={slide.logo} alt={slide.title} />
                  </div>
                  <img
                    src={slide.img}
                    alt={slide.title}
                    className="background-img"
                    loading="lazy"
                  />
                  <div className="slider-content">
                    <div className="slider-head-wrapper">
                      <div className="rating">
                        <div className="rating-item">
                          <div
                            style={{
                              width: window.innerWidth <= 1239 ? 41 : 60,
                              height: window.innerWidth <= 1239 ? 41 : 60,
                            }}
                          >
                            <ProgressProvider
                              valueStart={0}
                              valueEnd={slide.performance}
                            >
                              {(value) => (
                                <CircularProgressbar
                                  value={value}
                                  text={slide.performance}
                                />
                              )}
                            </ProgressProvider>
                          </div>
                          <small className="rating-label">Performa</small>
                        </div>
                        <div className="rating-item">
                          <div
                            style={{
                              width: window.innerWidth <= 1239 ? 41 : 60,
                              height: window.innerWidth <= 1239 ? 41 : 60,
                            }}
                          >
                            <ProgressProvider
                              valueStart={0}
                              valueEnd={slide.accessibility}
                            >
                              {(value) => (
                                <CircularProgressbar
                                  value={value}
                                  text={slide.accessibility}
                                />
                              )}
                            </ProgressProvider>
                          </div>
                          <small className="rating-label">Aksesibilitas</small>
                        </div>
                        <div className="rating-item">
                          <div
                            style={{
                              width: window.innerWidth <= 1239 ? 41 : 60,
                              height: window.innerWidth <= 1239 ? 41 : 60,
                            }}
                          >
                            <ProgressProvider
                              valueStart={0}
                              valueEnd={slide.bestPractice}
                            >
                              {(value) => (
                                <CircularProgressbar
                                  value={value}
                                  text={slide.bestPractice}
                                />
                              )}
                            </ProgressProvider>
                          </div>
                          <small className="rating-label">
                            Praktik Terbaik
                          </small>
                        </div>
                        <div className="rating-item">
                          <div
                            style={{
                              width: window.innerWidth <= 1239 ? 41 : 60,
                              height: window.innerWidth <= 1239 ? 41 : 60,
                            }}
                          >
                            <ProgressProvider
                              valueStart={0}
                              valueEnd={slide.seo}
                            >
                              {(value) => (
                                <CircularProgressbar
                                  value={value}
                                  text={slide.seo}
                                />
                              )}
                            </ProgressProvider>
                          </div>
                          <small className="rating-label">SEO</small>
                        </div>
                      </div>
                    </div>
                    <div className="slider-content-wrapper">
                      <div className="slider-content-label">
                        <h3 className="title">{slide.title}</h3>
                        <ul className="tags">
                          {slide.tags.map((tag, index) => (
                            <li key={index}>{tag}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <div className="showcase-content-arrow">
              <div className="left-arrow" onClick={next}>
                <ArrowSlider
                  width={15}
                  height={15}
                  fill="#FDFAFF"
                  className="inner-left-arrow
              "
                />
                <ArrowSlider
                  width={15}
                  height={15}
                  fill="#FDFAFF"
                  className="outer-left-arrow
              "
                />
              </div>
              <div className="right-arrow" onClick={previous}>
                <ArrowSlider
                  width={15}
                  height={15}
                  fill="#FDFAFF"
                  className="inner-right-arrow
              "
                />
                <ArrowSlider
                  width={15}
                  height={15}
                  fill="#FDFAFF"
                  className="outer-right-arrow
              "
                />
              </div>
            </div>
          </div>
          <div className="slider-item3">
            <Slider ref={addToRefs} {...settings} initialSlide={2}>
              {slidesData.map((slide, index) => (
                <img
                  src={slide.img}
                  key={index}
                  alt={slide.title}
                  loading="lazy"
                />
              ))}
            </Slider>
          </div>
        </div>
        <button
          onClick={() =>
            window.open(
              "https://api.whatsapp.com/send?phone=6285810758395&text=Halo%20Saya%C2%A0ingin%20konsultasi%20mengenai%20paket%20pembuatan%20website%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
              "_blank"
            )
          }
          className="read-more"
        >
          Hubungi Kami
          <div>
            <Arrowbtn
              width={window.innerWidth <= 1239 ? 17 : 30}
              height={window.innerWidth <= 1239 ? 17 : 30}
              fill="#001827"
              className="primary-arrow"
            />
            <Arrowbtn
              width={window.innerWidth <= 1239 ? 17 : 30}
              height={window.innerWidth <= 1239 ? 17 : 30}
              fill="#001827"
              className="secondary-arrow"
            />
          </div>
        </button>
      </div>
      <div className="service-pricing">
        <div className="container-pricing">
          <Line1 className="line-1" />
          <div className="table-pricing">
            <div className="pricing" id="e-commerce">
              <div className="label">
                <FontAwesomeIcon icon={faThumbsUp} className="icon" />
                <small>Just Relax</small>
              </div>
              <div className="content">
                <div className="content-head">
                  <div className="content-hero">
                    <h3 className="title">e-commerce</h3>
                  </div>
                  <div className="content-prices">
                    <div className="price-box">
                      <small className="overline">Mulai Dari</small>
                      <div className="price">
                        <small className="currency">Rp</small>
                        <h4 className="number">17.000.000</h4>
                      </div>
                      <small className="subscription">
                        Berlangganan Rp1.062.500/bln*
                      </small>
                    </div>
                    <div className="pricebutton">
                      <button
                        onClick={() =>
                          window.open(
                            "https://api.whatsapp.com/send?phone=6285810758395&text=Halo%20Saya%C2%A0ingin%20konsultasi%20mengenai%20paket%20website%20e-commerce%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
                            "_blank"
                          )
                        }
                        className="buttonwa"
                      >
                        Hubungi Kami
                        <div>
                          <Arrowbtn
                            width={18}
                            height={20}
                            fill="#FFFFFF"
                            className="primary-arrow"
                          />
                          <Arrowbtn
                            width={18}
                            height={20}
                            fill="#FFFFFF"
                            className="secondary-arrow"
                          />
                        </div>
                      </button>
                    </div>
                    <div className="benefit-lists">
                      {itempoints.map((item, index) => (
                        <div className="benefit" key={index}>
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            className="check"
                            size="lg"
                          />
                          <span>{item}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing" id="landing-page">
              <div className="label">
                <FontAwesomeIcon icon={faThumbsUp} className="icon" />
                <small>Best Price</small>
              </div>
              <div className="content">
                <div className="content-head">
                  <div className="content-hero">
                    <h3 className="title">landing page</h3>
                  </div>
                  <div className="content-prices">
                    <div className="price-box">
                      <small className="overline">Mulai Dari</small>
                      <div className="price">
                        <small className="currency">Rp</small>
                        <h4 className="number">5.800.000</h4>
                      </div>
                      <small className="subscription">
                        Berlangganan Rp362.500/bln*
                      </small>
                    </div>
                    <div className="pricebutton">
                      <button
                        onClick={() =>
                          window.open(
                            "https://api.whatsapp.com/send?phone=6285810758395&text=Halo%20Saya%C2%A0ingin%20konsultasi%20mengenai%20paket%20website%20landing%20page%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
                            "_blank"
                          )
                        }
                        className="buttonwa"
                      >
                        Hubungi Kami
                        <div>
                          <Arrowbtn
                            width={18}
                            height={20}
                            fill="#FFFFFF"
                            className="primary-arrow"
                          />
                          <Arrowbtn
                            width={18}
                            height={20}
                            fill="#FFFFFF"
                            className="secondary-arrow"
                          />
                        </div>
                      </button>
                    </div>
                    <div className="benefit-lists">
                      {itempoints.map((item, index) => (
                        <div className="benefit" key={index}>
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            className="check"
                            size="lg"
                          />
                          <span>{item}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing" id="company-profile">
              <div className="label">
                <FontAwesomeIcon icon={faThumbsUp} className="icon" />
                <small>Recommended</small>
              </div>
              <div className="content">
                <div className="content-head">
                  <div className="content-hero">
                    <h3 className="title">company profile</h3>
                  </div>
                  <div className="content-prices">
                    <div className="price-box">
                      <small className="overline">Mulai Dari</small>
                      <div className="price">
                        <small className="currency">Rp</small>
                        <h4 className="number">8.900.000</h4>
                      </div>
                      <small className="subscription">
                        Berlangganan Rp537.500/bln*
                      </small>
                    </div>
                    <div className="pricebutton">
                      <button
                        onClick={() =>
                          window.open(
                            "https://api.whatsapp.com/send?phone=6285810758395&text=Halo%20Saya%C2%A0ingin%20konsultasi%20mengenai%20paket%20website%20company%20profile%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
                            "_blank"
                          )
                        }
                        className="buttonwa"
                      >
                        Hubungi Kami
                        <div>
                          <Arrowbtn
                            width={18}
                            height={20}
                            fill="#FFFFFF"
                            className="primary-arrow"
                          />
                          <Arrowbtn
                            width={18}
                            height={20}
                            fill="#FFFFFF"
                            className="secondary-arrow"
                          />
                        </div>
                      </button>
                    </div>
                    <div className="benefit-lists">
                      {itempoints.map((item, index) => (
                        <div className="benefit" key={index}>
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            className="check"
                            size="lg"
                          />
                          <span>{item}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <small className="disclaimer">
              *Berlangganan dengan total 24 bulan, setelah 24 bulan website
              menjadi milik pribadi.
            </small>
          </div>

          <Line2 className="line-2" />
        </div>
      </div>
    </section>
  );
};

export default Webdev;
