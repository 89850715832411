import React, { useRef, useState, useEffect } from "react";
import "./SosmedV2.scss";
import intipesonalogo from "../../media/portfolio/intipesonalogo.svg";
import catalog from "../../media/portfolio/catalog-ntipesona_.webp";
import manlogo from "../../media/portfolio/manlogo.svg";
import mancatalog from "../../media/portfolio/mancatalog.png";
import rjcatalog from "../../media/portfolio/rjcatalog.png";
import proflightfav from "../../media/portfolio/proflight-fav.png";
import proflightlogo from "../../media/portfolio/proflighttext.svg";
import mockupphone from "../../media/portfolio/mockup-proflight.webp";
import mockuprj from "../../media/portfolio/mockup-rj.webp";
import mabowllogoround from "../../media/portfolio/mabowl-logo-round.png";
import mabowltext from "../../media/portfolio/mabowltext.svg";
import mockupmabowl from "../../media/portfolio/mockup-mabowl.webp";
import rjlogoround from "../../media/portfolio/rj-logo-round.png";
import rjtext from "../../media/portfolio/rjtext.webp";
import mockuplinktown from "../../media/portfolio/mockup-linktown.webp";
import linktownlogoround from "../../media/portfolio/linktown-logo-round.png";
import linktowntext from "../../media/portfolio/linktowntext.svg";
import { ReactComponent as Like } from "../../media/portfolio/like.svg";
import { ReactComponent as Comment } from "../../media/portfolio/comment.svg";
import { ReactComponent as Share } from "../../media/portfolio/share.svg";
import { ReactComponent as Save } from "../../media/portfolio/save.svg";
import { ReactComponent as Line1 } from "../../media/line-red1.svg";
import { ReactComponent as Line2 } from "../../media/line-red2.svg";
import { ReactComponent as Arrowbtn } from "../../media/up-right.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";

const settings = {
  dots: false,
  infinite: true,
  arrows: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  swipeToSlide: true,
  variableWidth: true,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1239,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        autoplay: false,
        speed: 500,
        cssEase: "ease",
      },
    },
    {
      breakpoint: 599,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        autoplay: false,
        speed: 500,
        cssEase: "ease",
      },
    },
  ],
};

const Sosmed = () => {
  const sliderRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState("craxone");
  const [selectedOption2, setSelectedOption2] = useState("");

  const options = ["Select an Option", "craxone", "craxtwo", "craxthree"];

  const handleSelectChange1 = (event) => {
    setSelectedOption1(event.target.value);
  };

  const handleSelectChange2 = (event) => {
    setSelectedOption2(event.target.value);
  };

  const getContentForOption = (option) => {
    switch (option) {
      case "craxone":
        return {
          priceBtn: (
            <div className="pricebutton2">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send?phone=6285810758395&text=Halo%20Saya%C2%A0ingin%20konsultasi%20mengenai%20paket%20Brand%20Activation%20CRAXONE%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
                    "_blank"
                  )
                }
                className="buttonwa"
              >
                Hubungi Kami
                <div>
                  <Arrowbtn
                    width={18}
                    height={20}
                    fill="#001827"
                    className="primary-arrow"
                  />
                  <Arrowbtn
                    width={18}
                    height={20}
                    fill="#001827"
                    className="secondary-arrow"
                  />
                </div>
              </button>
            </div>
          ),
          reels: "6 Reels",
          price: "3.500.000",
          feeds: "2 Feeds",
          stories: "5 Stories",
          contentPlan: (
            <FontAwesomeIcon className="icon" icon={faCircleCheck} />
          ),
          report: <FontAwesomeIcon className="icon" icon={faCircleCheck} />,
          rawFile: (
            <FontAwesomeIcon
              className="icon"
              icon={faCircleXmark}
              color="#5C5E6F"
            />
          ),
          strategicBranding: (
            <FontAwesomeIcon
              className="icon"
              icon={faCircleXmark}
              color="#5C5E6F"
            />
          ),
          analisisPesaing: (
            <FontAwesomeIcon
              className="icon"
              icon={faCircleXmark}
              color="#5C5E6F"
            />
          ),
          talent: <FontAwesomeIcon className="icon" icon={faCircleCheck} />,
          admin: (
            <FontAwesomeIcon
              className="icon"
              icon={faCircleXmark}
              color="#5C5E6F"
            />
          ),
          ads: (
            <FontAwesomeIcon
              className="icon"
              icon={faCircleXmark}
              color="#5C5E6F"
            />
          ),
          collab: (
            <FontAwesomeIcon
              className="icon"
              icon={faCircleXmark}
              color="#5C5E6F"
            />
          ),
          kol: (
            <FontAwesomeIcon
              className="icon"
              icon={faCircleXmark}
              color="#5C5E6F"
            />
          ),
        };
      case "craxtwo":
        return {
          priceBtn: (
            <div className="pricebutton2">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send?phone=6285810758395&text=Halo%20Saya%C2%A0ingin%20konsultasi%20mengenai%20paket%20Brand%20Activation%20CRAXTWO%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
                    "_blank"
                  )
                }
                className="buttonwa"
              >
                Hubungi Kami
                <div>
                  <Arrowbtn
                    width={18}
                    height={20}
                    fill="#001827"
                    className="primary-arrow"
                  />
                  <Arrowbtn
                    width={18}
                    height={20}
                    fill="#001827"
                    className="secondary-arrow"
                  />
                </div>
              </button>
            </div>
          ),
          reels: "10 Reels",
          price: "6.500.000",
          feeds: "6 Feeds",
          stories: "5 Stories",
          contentPlan: (
            <FontAwesomeIcon className="icon" icon={faCircleCheck} />
          ),
          report: <FontAwesomeIcon className="icon" icon={faCircleCheck} />,
          rawFile: (
            <FontAwesomeIcon
              className="icon"
              icon={faCircleXmark}
              color="#5C5E6F"
            />
          ),
          strategicBranding: (
            <FontAwesomeIcon className="icon" icon={faCircleCheck} />
          ),
          analisisPesaing: (
            <FontAwesomeIcon className="icon" icon={faCircleCheck} />
          ),
          talent: <FontAwesomeIcon className="icon" icon={faCircleCheck} />,
          admin: <FontAwesomeIcon className="icon" icon={faCircleCheck} />,
          ads: (
            <FontAwesomeIcon
              className="icon"
              icon={faCircleXmark}
              color="#5C5E6F"
            />
          ),
          collab: "1 times",
          kol: (
            <FontAwesomeIcon
              className="icon"
              icon={faCircleXmark}
              color="#5C5E6F"
            />
          ),
        };
      case "craxthree":
        return {
          priceBtn: (
            <div className="pricebutton2">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send?phone=6285810758395&text=Halo%20Saya%C2%A0ingin%20konsultasi%20mengenai%20paket%20Brand%20Activation%20CRAXTHREE%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
                    "_blank"
                  )
                }
                className="buttonwa"
              >
                Hubungi Kami
                <div>
                  <Arrowbtn
                    width={18}
                    height={20}
                    fill="#001827"
                    className="primary-arrow"
                  />
                  <Arrowbtn
                    width={18}
                    height={20}
                    fill="#001827"
                    className="secondary-arrow"
                  />
                </div>
              </button>
            </div>
          ),
          reels: "22 Reels",
          price: "12.000.000",
          feeds: "8 Feeds",
          stories: "7 Stories",
          contentPlan: (
            <FontAwesomeIcon className="icon" icon={faCircleCheck} />
          ),
          report: <FontAwesomeIcon className="icon" icon={faCircleCheck} />,
          rawFile: <FontAwesomeIcon className="icon" icon={faCircleCheck} />,
          strategicBranding: (
            <FontAwesomeIcon className="icon" icon={faCircleCheck} />
          ),
          analisisPesaing: (
            <FontAwesomeIcon className="icon" icon={faCircleCheck} />
          ),
          talent: <FontAwesomeIcon className="icon" icon={faCircleCheck} />,
          admin: <FontAwesomeIcon className="icon" icon={faCircleCheck} />,
          ads: <FontAwesomeIcon className="icon" icon={faCircleCheck} />,
          collab: "2 times",
          kol: <FontAwesomeIcon className="icon" icon={faCircleCheck} />,
        };
      default:
        return {
          reels: "",
        };
    }
  };

  const filteredOptions1 = options.filter(
    (option) => option !== selectedOption2
  );
  const filteredOptions2 = options.filter(
    (option) => option !== selectedOption1
  );
  const [navColor, setNavColor] = useState("transparent");
  const [backDrop, setBackDrop] = useState("none");
  const stickyRef = useRef(null);

  useEffect(() => {
    const currentRef = stickyRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(!entry.isIntersecting);
      },
      {
        threshold: 1,
        rootMargin: "-70px",
        root: document,
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [stickyRef]);

  useEffect(() => {
    if (isSticky) {
      setNavColor("transparent");
      setBackDrop("none");
    } else {
      setNavColor("rgba(55, 0, 1, 0.27)");
      setBackDrop("blur(3.6px)");
    }
  }, [isSticky]);
  if (selectedOption2 !== "") {
    filteredOptions2.shift("");
  }

  return (
    <div className="sosmedcont" id="brandactivation">
      <div className="head-sosmed">
        <div className="headleft">
          <div className="label">
            Yuk intip karya keren yang bikin brand jadi hits di dunia digital
            oleh kami!
          </div>
        </div>
        <div className="headright">
          <h2 className="title">BRAND ACTIVATION</h2>
        </div>
      </div>
      <div className="showcase-content ">
        <div className="slider">
          <Slider {...settings} ref={sliderRef}>
            <div className="card card-intipesona">
              <div className="card-top">
                <img
                  src={intipesonalogo}
                  alt="inti pesona"
                  className="image-top-physical"
                  loading="lazy"
                />
              </div>
              <div className="card-body">
                <img
                  src={catalog}
                  alt="catalog"
                  className="image-body-physical "
                  loading="lazy"
                />
              </div>
              <div className="card-bottom">
                <div className="card-info-physical-1">
                  <ul>
                    <li>Katalog Produk</li>
                    <li>PT. Inti Pesona Lestari</li>
                  </ul>
                </div>
                <div className="card-info-physical-2">
                  <ul>
                    <li>12 Feb 2024</li>
                    <li>intipesonalestari.com</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card card-man">
              <div className="card-top">
                <img src={manlogo} alt="man" className="image-top-physical" />
              </div>
              <div className="card-body">
                <img
                  src={mancatalog}
                  alt="man"
                  className="image-body-physical "
                  loading="lazy"
                />
              </div>
              <div className="card-bottom">
                <div className="card-info-physical-1">
                  <ul>
                    <li>Lanyard + ID Card</li>
                    <li>MAN Kota Surabaya</li>
                  </ul>
                </div>
                <div className="card-info-physical-2">
                  <ul>
                    <li>23 Sep 2023</li>
                    <li>Campex Manesa 2024</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card card-rj">
              <div className="card-top">
                <img
                  src={rjtext}
                  alt="ruang jenuh coffe"
                  className="image-top-physical"
                  loading="lazy"
                />
              </div>
              <div className="card-body">
                <img
                  src={rjcatalog}
                  alt="ruang jenuh coffe"
                  className="image-body-physical "
                  loading="lazy"
                />
              </div>
              <div className="card-bottom">
                <div className="card-info-physical-1">
                  <ul>
                    <li>Logo</li>
                    <li>Ruang Jenuh Coffee</li>
                  </ul>
                </div>
                <div className="card-info-physical-2">
                  <ul>
                    <li>5 Sep 2022</li>
                    <li>@ruangjenuh_coffee</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card card-proflight">
              <div className="card-top">
                <img
                  src={proflightfav}
                  alt="proflight"
                  className="image-top-digital"
                  loading="lazy"
                />
                <div>
                  <ul>
                    <li>proflight_indonesia</li>
                    <li>Proflight Indonesia Official</li>
                  </ul>
                </div>
              </div>
              <div className="card-body">
                <div className="info-view">
                  <div>
                    <ul>
                      <li>46,7 Ribu Penonton!</li>
                      <li>Pemutaran Konten</li>
                    </ul>
                  </div>
                  <img
                    src={proflightlogo}
                    alt="proflight"
                    className="image-body-logo"
                    loading="lazy"
                  />
                </div>
                <img
                  src={mockupphone}
                  alt="phone"
                  className="image-body-digital"
                  loading="lazy"
                />
              </div>
              <div className="card-bottom">
                <div className="card-info-digital">
                  <Like></Like>
                  104
                </div>
                <div className="card-info-digital">
                  <Comment></Comment>2
                </div>
                <div className="card-info-digital">
                  <Share></Share>6
                </div>
                <div className="card-info-digital">
                  <Save></Save>
                  1,5K
                </div>
              </div>
            </div>
            <div className="card card-mabowl">
              <div className="card-top">
                <img
                  src={mabowllogoround}
                  alt="mabowl"
                  className="image-top-digital"
                  loading="lazy"
                />
                <div>
                  <ul>
                    <li>mabowll</li>
                    <li>MaBowl | Japanese Contemporary Ricebowl</li>
                  </ul>
                </div>
              </div>
              <div className="card-body">
                <div className="info-view">
                  <div>
                    <ul>
                      <li>3,39 Ribu Penonton!</li>
                      <li>Pemutaran Konten</li>
                    </ul>
                  </div>
                  <img
                    src={mabowltext}
                    alt="mabowl"
                    loading="lazy"
                    className="image-body-logo"
                  />
                </div>
                <img
                  src={mockupmabowl}
                  alt="mabowl"
                  className="image-body-digital"
                  loading="lazy"
                />
              </div>
              <div className="card-bottom">
                <div className="card-info-digital">
                  <Like></Like>
                  55
                </div>
                <div className="card-info-digital">
                  <Comment></Comment>
                  24
                </div>
                <div className="card-info-digital">
                  <Share></Share>
                  33
                </div>
                <div className="card-info-digital">
                  <Save></Save>
                  700
                </div>
              </div>
            </div>
            <div className="card card-rj">
              <div className="card-top">
                <img
                  src={rjlogoround}
                  alt="logo rj"
                  className="image-top-digital"
                  loading="lazy"
                />
                <div>
                  <ul>
                    <li>ruangjenuh_coffee</li>
                    <li>Ruang Jenuh</li>
                  </ul>
                </div>
              </div>
              <div className="card-body">
                <div className="info-view">
                  <div>
                    <ul>
                      <li>3,05 Ribu Penonton!</li>
                      <li>Pemutaran Konten</li>
                    </ul>
                  </div>
                  <img
                    src={rjtext}
                    alt="ruang jenuh coffe"
                    className="image-body-logo"
                    loading="lazy"
                  />
                </div>
                <img
                  src={mockuprj}
                  alt="ruang jenuh coffe"
                  className="image-body-digital"
                  loading="lazy"
                />
              </div>
              <div className="card-bottom">
                <div className="card-info-digital">
                  <Like></Like>
                  79
                </div>
                <div className="card-info-digital">
                  <Comment></Comment>
                  25
                </div>
                <div className="card-info-digital">
                  <Share></Share>
                  29
                </div>
                <div className="card-info-digital">
                  <Save></Save>
                  550
                </div>
              </div>
            </div>

            <div className="card card-linktown">
              <div className="card-top">
                <img
                  src={linktownlogoround}
                  alt="linktown"
                  className="image-top-digital"
                  loading="lazy"
                />
                <div>
                  <ul>
                    <li>linktown.co.id</li>
                    <li>#1 Rumah Linktown</li>
                  </ul>
                </div>
              </div>
              <div className="card-body">
                <div className="info-view">
                  <div>
                    <ul>
                      <li> 52,9 Ribu Penonton!</li>
                      <li>Pemutaran Konten</li>
                    </ul>
                  </div>
                  <img
                    src={linktowntext}
                    alt="linktown"
                    className="image-body-logo"
                    loading="lazy"
                  />
                </div>
                <img
                  src={mockuplinktown}
                  alt="linktown"
                  className="image-body-digital"
                  loading="lazy"
                />
              </div>
              <div className="card-bottom">
                <div className="card-info-digital">
                  <Like></Like>
                  345
                </div>
                <div className="card-info-digital">
                  <Comment></Comment>39
                </div>
                <div className="card-info-digital">
                  <Share></Share>52
                </div>
                <div className="card-info-digital">
                  <Save></Save>
                  1,5K
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      <div className="service-pricing">
        <div className="container-pricing">
          <Line1 className="line-1" />
          {/* desktop */}
          <div className="table-pricing desktop">
            <div
              className="pricing-header"
              ref={stickyRef}
              style={{
                backgroundColor: navColor, // Apply navColor here
                transition: "background-color 0.3s ease",
                backdropFilter: backDrop,
              }}
            >
              <div className="item-head">
                <b className="item-text">Plan</b>
              </div>
              <div className="content-head">
                <div className="content-box">
                  <h3 className="title">CRAXONE</h3>
                  <small className="caption">Mulai dari</small>
                  <div className="price">
                    <b className="currency">Rp</b>
                    <h4 className="value">3.500.000</h4>
                  </div>
                </div>
                <div className="pricebutton">
                  <button
                    onClick={() =>
                      window.open(
                        "https://api.whatsapp.com/send?phone=6285810758395&text=Halo%20Saya%C2%A0ingin%20konsultasi%20mengenai%20paket%20Brand%20Activation%20CRAXONE%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
                        "_blank"
                      )
                    }
                    className="buttonwa"
                  >
                    Hubungi Kami
                    <div>
                      <Arrowbtn
                        width={18}
                        height={20}
                        fill="#001827"
                        className="primary-arrow"
                      />
                      <Arrowbtn
                        width={18}
                        height={20}
                        fill="#001827"
                        className="secondary-arrow"
                      />
                    </div>
                  </button>
                </div>
              </div>
              <div className="content-head">
                <div className="content-box">
                  <h3 className="title">CRAXTWO</h3>
                  <small className="caption">Mulai dari</small>
                  <div className="price">
                    <b className="currency">Rp</b>
                    <h4 className="value">6.500.000</h4>
                  </div>
                </div>
                <div className="pricebutton">
                  <button
                    onClick={() =>
                      window.open(
                        "https://api.whatsapp.com/send?phone=6285810758395&text=Halo%20Saya%C2%A0ingin%20konsultasi%20mengenai%20paket%20Brand%20Activation%20CRAXTWO%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
                        "_blank"
                      )
                    }
                    className="buttonwa"
                  >
                    Hubungi Kami
                    <div>
                      <Arrowbtn
                        width={18}
                        height={20}
                        fill="#001827"
                        className="primary-arrow"
                      />
                      <Arrowbtn
                        width={18}
                        height={20}
                        fill="#001827"
                        className="secondary-arrow"
                      />
                    </div>
                  </button>
                </div>
              </div>
              <div className="content-head">
                <div className="content-box">
                  <h3 className="title">CRAXTHREE</h3>
                  <small className="caption">Mulai dari</small>
                  <div className="price">
                    <b className="currency">Rp</b>
                    <h4 className="value">12.000.000</h4>
                  </div>
                </div>
                <div className="pricebutton">
                  <button
                    onClick={() =>
                      window.open(
                        "https://api.whatsapp.com/send?phone=6285810758395&text=Halo%20Saya%C2%A0ingin%20konsultasi%20mengenai%20paket%20Brand%20Activation%20CRAXTHREE%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
                        "_blank"
                      )
                    }
                    className="buttonwa"
                  >
                    Hubungi Kami
                    <div>
                      <Arrowbtn
                        width={18}
                        height={20}
                        fill="#001827"
                        className="primary-arrow"
                      />
                      <Arrowbtn
                        width={18}
                        height={20}
                        fill="#001827"
                        className="secondary-arrow"
                      />
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Reels</div>
              <div className="item-content">6 Reels</div>
              <div className="item-content">10 Reels</div>
              <div className="item-content">22 Reels</div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Feeds</div>
              <div className="item-content">2 Feeds</div>
              <div className="item-content">6 Feeds</div>
              <div className="item-content">8 Feeds</div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Stories/Week</div>
              <div className="item-content">5 Stories</div>
              <div className="item-content">5 Stories</div>
              <div className="item-content">7 Stories</div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Content Plan</div>
              <div className="item-content">
                <FontAwesomeIcon className="icon" icon={faCircleCheck} />
              </div>
              <div className="item-content">
                <FontAwesomeIcon className="icon" icon={faCircleCheck} />
              </div>
              <div className="item-content">
                <FontAwesomeIcon className="icon" icon={faCircleCheck} />
              </div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Report Monthly</div>
              <div className="item-content">
                <FontAwesomeIcon className="icon" icon={faCircleCheck} />
              </div>
              <div className="item-content">
                <FontAwesomeIcon className="icon" icon={faCircleCheck} />
              </div>
              <div className="item-content">
                <FontAwesomeIcon className="icon" icon={faCircleCheck} />
              </div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Raw File</div>
              <div className="item-content">
                <FontAwesomeIcon
                  className="icon"
                  icon={faCircleXmark}
                  color="#5C5E6F"
                />
              </div>
              <div className="item-content">
                <FontAwesomeIcon
                  className="icon"
                  icon={faCircleXmark}
                  color="#5C5E6F"
                />
              </div>
              <div className="item-content">
                <FontAwesomeIcon className="icon" icon={faCircleCheck} />
              </div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Strategic Branding</div>
              <div className="item-content">
                <FontAwesomeIcon
                  className="icon"
                  icon={faCircleXmark}
                  color="#5C5E6F"
                />
              </div>
              <div className="item-content">
                <FontAwesomeIcon className="icon" icon={faCircleCheck} />
              </div>
              <div className="item-content">
                <FontAwesomeIcon className="icon" icon={faCircleCheck} />
              </div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Analisis Pesaing</div>
              <div className="item-content">
                <FontAwesomeIcon
                  className="icon"
                  icon={faCircleXmark}
                  color="#5C5E6F"
                />
              </div>
              <div className="item-content">
                <FontAwesomeIcon className="icon" icon={faCircleCheck} />
              </div>
              <div className="item-content">
                <FontAwesomeIcon className="icon" icon={faCircleCheck} />
              </div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Talent</div>
              <div className="item-content">
                <FontAwesomeIcon className="icon" icon={faCircleCheck} />
              </div>
              <div className="item-content">
                <FontAwesomeIcon className="icon" icon={faCircleCheck} />
              </div>
              <div className="item-content">
                <FontAwesomeIcon className="icon" icon={faCircleCheck} />
              </div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Admin</div>
              <div className="item-content">
                <FontAwesomeIcon
                  className="icon"
                  icon={faCircleXmark}
                  color="#5C5E6F"
                />
              </div>
              <div className="item-content">
                <FontAwesomeIcon className="icon" icon={faCircleCheck} />
              </div>
              <div className="item-content">
                <FontAwesomeIcon className="icon" icon={faCircleCheck} />
              </div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Ads Expert</div>
              <div className="item-content">
                <FontAwesomeIcon
                  className="icon"
                  icon={faCircleXmark}
                  color="#5C5E6F"
                />
              </div>
              <div className="item-content">
                <FontAwesomeIcon
                  className="icon"
                  icon={faCircleXmark}
                  color="#5C5E6F"
                />
              </div>
              <div className="item-content">
                <FontAwesomeIcon className="icon" icon={faCircleCheck} />
              </div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Free times Collaboration</div>
              <div className="item-content">
                <FontAwesomeIcon
                  className="icon"
                  icon={faCircleXmark}
                  color="#5C5E6F"
                />
              </div>
              <div className="item-content">1 times</div>
              <div className="item-content">2 times</div>
            </div>
            <div className="pricing-item ">
              <div className="item-text">Free KOL Post</div>
              <div className="item-content">
                <FontAwesomeIcon
                  className="icon"
                  icon={faCircleXmark}
                  color="#5C5E6F"
                />
              </div>
              <div className="item-content">
                <FontAwesomeIcon
                  className="icon"
                  icon={faCircleXmark}
                  color="#5C5E6F"
                />
              </div>
              <div className="item-content">1 KOL </div>
            </div>
          </div>
          {/* mobile */}
          <div className="table-pricing mobile">
            <div className="header">
              <div className="headertitle">
                <div className="headerbox">
                  <select
                    aria-label="option"
                    value={selectedOption1}
                    onChange={handleSelectChange1}
                    className="selectbox"
                  >
                    {filteredOptions1.map((option) =>
                      option !== "Select an Option" ? (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ) : null
                    )}
                  </select>
                  <small className="caption">Mulai dari</small>
                  <div className="price">
                    <b className="currency">Rp</b>
                    <h2 className="value">
                      {selectedOption1 !== ""
                        ? getContentForOption(selectedOption1).price
                        : ""}
                    </h2>
                  </div>
                </div>
                {selectedOption1 !== ""
                  ? getContentForOption(selectedOption1).priceBtn
                  : ""}
              </div>
              <div className="headertitle">
                <div className="headerbox">
                  <select
                    aria-label="option"
                    value={selectedOption2}
                    onChange={handleSelectChange2}
                  >
                    {filteredOptions2.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <small className="caption">Mulai dari</small>
                  <div className="price">
                    <b className="currency">Rp</b>
                    <h2 className="value">
                      {selectedOption2 !== ""
                        ? getContentForOption(selectedOption2).price
                        : ""}
                    </h2>
                  </div>
                </div>
                {selectedOption2 !== ""
                  ? getContentForOption(selectedOption2).priceBtn
                  : ""}
              </div>
            </div>

            <div className="pricing-item">
              <div className="item-text">Reels</div>
              <div className="item-content">
                {selectedOption1 !== ""
                  ? getContentForOption(selectedOption1).reels
                  : ""}
              </div>
              <div className="item-content">
                {selectedOption2 !== ""
                  ? getContentForOption(selectedOption2).reels
                  : ""}
              </div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Feeds</div>
              <div className="item-content">
                {selectedOption1 !== ""
                  ? getContentForOption(selectedOption1).feeds
                  : ""}
              </div>
              <div className="item-content">
                {selectedOption2 !== ""
                  ? getContentForOption(selectedOption2).feeds
                  : ""}
              </div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Stories/Week</div>
              <div className="item-content">
                {selectedOption1 !== ""
                  ? getContentForOption(selectedOption1).stories
                  : ""}
              </div>
              <div className="item-content">
                {selectedOption2 !== ""
                  ? getContentForOption(selectedOption2).stories
                  : ""}
              </div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Content Plan</div>
              <div className="item-content">
                {selectedOption1 !== ""
                  ? getContentForOption(selectedOption1).contentPlan
                  : ""}
              </div>
              <div className="item-content">
                {selectedOption2 !== ""
                  ? getContentForOption(selectedOption2).contentPlan
                  : ""}
              </div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Report Monthly</div>
              <div className="item-content">
                {selectedOption1 !== ""
                  ? getContentForOption(selectedOption1).report
                  : ""}
              </div>
              <div className="item-content">
                {selectedOption2 !== ""
                  ? getContentForOption(selectedOption2).report
                  : ""}
              </div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Raw File</div>
              <div className="item-content">
                {selectedOption1 !== ""
                  ? getContentForOption(selectedOption1).rawFile
                  : ""}
              </div>
              <div className="item-content">
                {selectedOption2 !== ""
                  ? getContentForOption(selectedOption2).rawFile
                  : ""}
              </div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Strategic Branding</div>
              <div className="item-content">
                {selectedOption1 !== ""
                  ? getContentForOption(selectedOption1).strategicBranding
                  : ""}
              </div>
              <div className="item-content">
                {selectedOption2 !== ""
                  ? getContentForOption(selectedOption2).strategicBranding
                  : ""}
              </div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Analisis Pesaing</div>
              <div className="item-content">
                {selectedOption1 !== ""
                  ? getContentForOption(selectedOption1).analisisPesaing
                  : ""}
              </div>
              <div className="item-content">
                {selectedOption2 !== ""
                  ? getContentForOption(selectedOption2).analisisPesaing
                  : ""}
              </div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Talent</div>
              <div className="item-content">
                {selectedOption1 !== ""
                  ? getContentForOption(selectedOption1).talent
                  : ""}
              </div>
              <div className="item-content">
                {selectedOption2 !== ""
                  ? getContentForOption(selectedOption2).talent
                  : ""}
              </div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Admin</div>
              <div className="item-content">
                {selectedOption1 !== ""
                  ? getContentForOption(selectedOption1).admin
                  : ""}
              </div>
              <div className="item-content">
                {selectedOption2 !== ""
                  ? getContentForOption(selectedOption2).admin
                  : ""}
              </div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Ads Expert</div>
              <div className="item-content">
                {selectedOption1 !== ""
                  ? getContentForOption(selectedOption1).ads
                  : ""}
              </div>
              <div className="item-content">
                {selectedOption2 !== ""
                  ? getContentForOption(selectedOption2).ads
                  : ""}
              </div>
            </div>
            <div className="pricing-item">
              <div className="item-text">Free times Collaboration</div>
              <div className="item-content">
                {selectedOption1 !== ""
                  ? getContentForOption(selectedOption1).collab
                  : ""}
              </div>
              <div className="item-content">
                {selectedOption2 !== ""
                  ? getContentForOption(selectedOption2).collab
                  : ""}
              </div>
            </div>
            <div className="pricing-item ">
              <div className="item-text">Free KOL Post</div>
              <div className="item-content">
                {selectedOption1 !== ""
                  ? getContentForOption(selectedOption1).kol
                  : ""}
              </div>
              <div className="item-content">
                {selectedOption2 !== ""
                  ? getContentForOption(selectedOption2).kol
                  : ""}
              </div>
            </div>
          </div>
          <Line2 className="line-2" />
        </div>
      </div>
    </div>
  );
};

export default Sosmed;
