import { React } from "react";
import "./HeroV2.scss";

const Hero = () => {
  return (
    <section className="head-container">
      <div className="welcome-container">
        <h1 className="headline">WE EMPOWER BRANDS TO STAND OUT</h1>
      </div>
    </section>
  );
};

export default Hero;
