import React, { useEffect, useRef, useState, useMemo } from "react";
import "./AppdevV2.scss";
import { ReactComponent as Globe } from "../../media/globe-icon.svg";
import { ReactComponent as Mobile } from "../../media/mobile-icon.svg";
import { ReactComponent as Desktop } from "../../media/desktop-icon.svg";
import Slider from "react-slick";
import appslider1 from "../../media/appsSlider/slider1.png";
import appslider2 from "../../media/appsSlider/slider2.png";
import appslider3 from "../../media/appsSlider/slider3.png";
import { ReactComponent as Arrowbtn } from "../../media/up-right.svg";
const Appdev = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderEnabled, setSliderEnabled] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      // Adjust the breakpoint as needed
      setSliderEnabled(width <= 599); // Disable for tablet and desktop (width > 768)
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const slideritem = [
    {
      id: "slider-webapp",
      title: "WEB APPS YANG MELESAT!",
      subtitle:
        "Bingung cara bikin website yang menarik? Ayo, kolaborasi sama kami dan wujudkan ide-ide gila kamu jadi kenyataan! Hubungi kami sekarang!",
      buttontext: "Web Apps",
      img: appslider1,
    },
    {
      id: "slider-mobileapp",
      title: "MOBILE APPS YANG MEMIKAT!",
      subtitle:
        "Genggam peluang dengan aplikasi mobile seru! Kolaborasi bareng kami untuk wujudkan ide-ide kreatif kamu dan bikin pengguna jatuh cinta! Hubungi kami sekarang!",
      buttontext: "Mobile Apps",
      img: appslider2,
    },
    {
      id: "slider-desktopapp",
      title: "APLIKASI DESKTOP YANG EFISIEN!",
      subtitle:
        "Genggam peluang dengan aplikasi mobile seru! Kolaborasi bareng kami untuk wujudkan ide-ide kreatif kamu dan bikin pengguna jatuh cinta! Hubungi kami sekarang!",
      buttontext: "Desktop Apps",
      img: appslider3,
    },
  ];
  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    vertical: true,
    verticalSwiping: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    afterChange: (newIndex) => {
      setCurrentSlide(newIndex);
    },
    responsive: [
      {
        breakpoint: 1239,
        settings: {
          vertical: false,
          verticalSwiping: false,
        },
      },
    ],
  };
  const button1Active = useMemo(() => currentSlide === 0, [currentSlide]);
  const button2Active = useMemo(() => currentSlide === 1, [currentSlide]);
  const button3Active = useMemo(() => currentSlide === 2, [currentSlide]);

  function goToSlide1() {
    slider.current.slickGoTo(0); // Go to slide 1 (index 0)
  }

  function goToSlide2() {
    slider.current.slickGoTo(1); // Go to slide 2 (index 1)
  }

  function goToSlide3() {
    slider.current.slickGoTo(2); // Go to slide 3 (index 2)
  }

  const slider = useRef(null);
  return (
    <section className="appdevcont" id="appsdevelopment">
      <div className="head-appdev">
        <div className="headleft">
          <div className="label">
            Tim kami bakal bantu kamu bikin aplikasi custom yang nggak hanya
            kece, tapi juga super user-friendly!
          </div>
        </div>
        <div className="headright">
          <h2 className="title">APP DEVELOPMENT</h2>
        </div>
      </div>
      <div className="container">
        <div className="center">
          <div className="buttonslider">
            <div className="buttoncontainer">
              <button
                aria-label="Web Apps"
                className={`buttonapp ${button1Active ? "active" : ""}`}
                onClick={() => {
                  setCurrentSlide(0);
                  goToSlide1();
                }}
              >
                <Globe /> &nbsp;Web Apps
              </button>
              <div
                className={`garisbutton ${button1Active ? "active" : ""}`}
                onClick={() => {
                  setCurrentSlide(0);
                  goToSlide1();
                }}
              ></div>
            </div>
            <div className="buttoncontainer">
              <button
                aria-label="Mobile Apps"
                className={`buttonapp ${button2Active ? "active" : ""}`}
                onClick={() => {
                  setCurrentSlide(1);
                  goToSlide2();
                }}
              >
                <Mobile /> &nbsp;Mobile Apps
              </button>
              <div
                className={`garisbutton ${button2Active ? "active" : ""}`}
                onClick={() => {
                  setCurrentSlide(1);
                  goToSlide2();
                }}
              ></div>
            </div>
            <div className="buttoncontainer">
              <button
                aria-label="Desktop Apps"
                className={`buttonapp ${button3Active ? "active" : ""}`}
                onClick={() => {
                  setCurrentSlide(2);
                  goToSlide3();
                }}
              >
                <Desktop /> &nbsp;Desktop Apps
              </button>
              <div
                className={`garisbutton ${button3Active ? "active" : ""}`}
                onClick={() => {
                  setCurrentSlide(2);
                  goToSlide3();
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="slidercenter">
          <div className="sliderapp">
            <Slider {...settings} ref={slider}>
              {slideritem.map((slide, index) => (
                <div>
                  <div className="slideritem" key={index} id={slide.id}>
                    <div className="slidercontent">
                      <div className="title">{slide.title}</div>
                      <div className="subtitle">{slide.subtitle}</div>
                      <button
                        aria-label="Konsultasikan Sekarang"
                        onClick={() =>
                          window.open(
                            "https://api.whatsapp.com/send?phone=6285810758395&text=Halo%20Saya%C2%A0ingin%20konsultasi%20mengenai%20pembuatan%20aplikasi%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
                            "_blank"
                          )
                        }
                        className="buttonwa"
                      >
                        Konsultasikan Sekarang
                        <div>
                          <Arrowbtn
                            width={18}
                            height={20}
                            fill="#001827"
                            className="primary-arrow"
                          />
                          <Arrowbtn
                            width={18}
                            height={20}
                            fill="#001827"
                            className="secondary-arrow"
                          />
                        </div>
                      </button>
                    </div>
                    <div className="sliderimage">
                      <img
                        src={slide.img}
                        className="imgsl"
                        alt="craxion.slider"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Appdev;
